module.exports = [{
      plugin: require('../node_modules/@nextblick/gatsby-theme-hdb/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"209855f016e773a3a868bf584e8c3ec8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"thiele","accessToken":"MC5aVXpEVEJJQUFDRUFoOHB4.PU8-IO-_vSbvv73vv73vv71F77-977-9SD8DEFJxEnTvv71HXH7vv70v77-9Vu-_vSzvv70A"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"custom":[]},
    },{
      plugin: require('../node_modules/@spencerwhyte/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-H9TGSDRDM0","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production"]},
    },{
      plugin: require('../node_modules/@nextblick/gatsby-theme-hdb/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"custom":[{"name":"Apercu","file":"/fonts/apercu/apercu.css"},{"name":"Arial","file":"/fonts/arial-rounded-mt/arial-rounded-mt.css"},{"name":"Satisfy","file":"/fonts/satisfy/satisfy.css"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
