import baseTheme from "@nextblick/gatsby-theme-hdb/src/gatsby-plugin-theme-ui";
import { merge } from "theme-ui";

// merge will deeply merge custom values with the theme's defaults
export default merge(baseTheme, {
  colors: {
    text: "#000",
    primary: "#8a1812",
    secondary: "#af9264",
    backgroundLight: "#f7f3e3",
    headerBg: "#8a1812",
    headerText: "#fff",
  },
  sizes: {
    headerHeight: 80,
    headerLogoHeight: 60,
    subMenuHeight: 60,
  },
  fonts: {
    body: "'Apercu Light', sans-serif",
    heading: "'Arial Rounded MT Bold', Arial, sans-serif",
    monospace: "monospace",
  },
  text: {
    boxed: {
      backgroundColor: "transparent",
      color: "text",
      textTransform: "uppercase",
    },
    title: {
      fontFamily: "heading",
      textShadow: "0 1px 0 rgba(0, 0, 0, 0.25)",
    },
    nav: {
      fontSize: 3,
      "&.active, &:hover": {
        color: "secondary",
      },
    },
  },
  layout: {
    nav: {
      gap: 4,
    },
    cta: {
      backgroundColor: "primary",
      color: "white",
    },
  },
});
